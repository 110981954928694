<div class="autocomplete-container"
    #popperInfo="popper"
    (mouseenter)="mouseEnter($event)"
    (mouseleave)="mouseLeave($event)"
    [popper]="value?.description ?? ''"
    [popperDisabled]="!value?.description || value?.description?.length == 0 || (showErrorTooltip && !isDisabled && computedErrors?.length > 0)"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="info"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [popperDelay]="1500"
    [ngClass]="uniqueId"
    [class.is-locked]="isLocked && !isDisabled"
    [class.is-searchable]="isSearchable"
    [class.zoom-disabled]="isDisabled">
    <ng-select
        #popperError="popper"
        [popper]="tooltipErrTemplate"
        [popperDisabled]="!(showErrorTooltip && !isDisabled && computedErrors?.length > 0)"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        popperApplyClass="error"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [typeToSearchText]="typeToSearchText"
        [popperHideOnScroll]="true"
        popperAppendTo="body"
        (open)="onDropdownOpen()"
        (close)="onDropdownClose()"
        (blur)="blur()"
        (clear)="onClear()"
        (focus)="focus($event)"
        (change)="onSelectedItem($event)"
        [(ngModel)]="value"
        [selectOnTab]="false"
        [isOpen]="isSearchable ? (isDropdownOpen | async): undefined"
        [appendTo]="appendTo"
        [searchable]="isSearchable"
        [readonly]="isDisabled || isLocked"
        [loading]="isLoading"
        [multiple]="false"
        [keyDownFn]="keyDownFn.bind(this)"
        [trackByFn]="trackByFn"
        [typeahead]="isSearchable ? itemInput$: undefined"
        [class.search-hidden]="searchCommand == null || !(searchCommand.isVisible$ | async)"
        [class.has-error]="showErrorBorder && computedErrors?.length > 0"
        [class.is-loading]="isLoading"
        [class.has-clear]="inputRef?.showClear()"
        [class.deactivated]="isSelectedItemDeactivated()"
        [items]="items$ | async"
        class="external-box"
        notFoundText="{{'NessunElementoTrovato' | NTSTranslate}}"
        clearAllText="{{'Pulisci' | NTSTranslate}}"
        loadingText="{{'CaricamentoInProgress' | NTSTranslate}}"
        dropdownPosition="bottom"
        [placeholder]="computedPlaceholder"
        bindLabel="description">
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span [attr.title]="item.description" class="ng-option-label" [ngOptionHighlight]="search"
                [class.deactivated]="isItemDeactivated(item)">{{item.description}}</span>
        </ng-template>
        <ng-template ng-footer-tmp>
            <div class="actions-container">
                <div class="left-actions">
                    <nts-text-button
                        *ngIf="copyCommand && (copyCommand.isVisible$ | async)"
                        [title]="copyCommand.description"
                        [additionalClasses]="[inputRef?.dropdownId]"
                        [isDisabled]="!(copyCommand.canExecute$ | async)"
                        [icon]="copyCommand.iconClass"
                        (onClicked)="copyCommand.execute()"></nts-text-button>
                    <nts-text-button
                        *ngIf="viewItemCommand && (viewItemCommand.isVisible$ | async)"
                        [title]="viewItemCommand.description"
                        [additionalClasses]="[inputRef?.dropdownId]"
                        [isDisabled]="!(viewItemCommand.canExecute$ | async)"
                        [icon]="viewItemCommand.iconClass"
                        (onClicked)="viewItemCommand.execute(externalBoxCommandUtility)"></nts-text-button>
                </div>
                <div class="right-actions">
                    <nts-text-button
                        *ngIf="addItemCommand && (addItemCommand.isVisible$ | async)"
                        [title]="addItemCommand.description"
                        [label]="addItemCommand.displayName"
                        [additionalClasses]="[inputRef?.dropdownId]"
                        [isDisabled]="!(addItemCommand.canExecute$ | async)"
                        [icon]="addItemCommand.iconClass"
                        (onClicked)="addItemCommand.execute()"></nts-text-button>
                </div>
            </div>
        </ng-template>
    </ng-select>
    <div class="autocomplete-actions"
        *ngIf="!isDisabled && !isLocked && isSearchable">
        <nts-text-button
            *ngIf="searchCommand && (searchCommand.isVisible$ | async)"
            [title]="searchCommand.description"
            (onClicked)="searchCommand.execute()"
            [isDisabled]="!(searchCommand.canExecute$ | async)"
            [tabIndex]="-1"
            [icon]="searchCommand.iconClass"></nts-text-button>
        <nts-text-button (onClicked)="toggleDropdown()" [tabIndex]="-1" icon="vert"></nts-text-button>
    </div>

</div>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of computedErrors">
        {{ item }}
    </ng-container>
</popper-content>
