import { Inject, Injectable, Optional } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { RouteChangeParams } from "./route-change-params";
import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PresentationCache } from "../cache/presentation-cache";
import { AuthService } from "../auth/auth.service";
import { classToPlain, ClassConstructor, plainToClass } from '@nts/std/serialization';
import { EnvironmentConfiguration, UNPROXIED_ENVIRONMENT } from '@nts/std/environments';
import { EnvironmentHelper } from '@nts/std/environments';
import { Message } from "@nts/std/utility";
import { CurrentRouteService } from "./current-route.service";

export interface WindowSize {
    outerHeight: number;
    outerWidth: number;
}

@Injectable({
    providedIn: 'root',
})
export class RoutingService {

    onTabMessageReceived = new Subject<{message: Message<string>, action: (payload: any) => void}>();
    routeChangeRequested = new Subject<RouteChangeParams>();
    private iFrameFocusChanged = new BehaviorSubject<Window>(null);

    private _inIframe = false;
    get inIframe() {
        return this._inIframe;
    }

    constructor(
        private readonly router: Router,
        private readonly location: Location,
        private readonly environmentConfiguration: EnvironmentConfiguration,
        private readonly authService: AuthService,
        private readonly currentRouteService: CurrentRouteService,
        @Optional()
        @Inject(UNPROXIED_ENVIRONMENT) private readonly unproxiedEnvironment: EnvironmentConfiguration,
    ) {
        this._inIframe = window.location.href.includes('iframe=true') && window.self != window.top;
    }

    handleIFrameFocusChanged(): Observable<WindowSize> {
        return this.iFrameFocusChanged.asObservable();
    }

    triggerIFrameFocusChanged(window): void {
        this.iFrameFocusChanged.next(window);
    }

    // TODO encode/decode utf8
    // https://medium.com/@nikitafed9292/net-base64-decoding-workaround-82b797162b6e
    // TODO encode con supporto utf8
    // var bytes = Encoding.UTF8.GetBytes(source);
    // var encodedUrlFriendly = Convert.ToBase64String(bytes).TrimEnd(‘=’).Replace(‘+’, ‘-’).Replace(‘/’, ‘_’);
    // TODO decode con supporto utf8
    // encoded = encoded.Replace(‘-’, ‘+’).Replace(‘_’, ‘/’);
    // var d = encoded.Length % 4;
    // if (d != 0)
    // {
    //     encoded = encoded.TrimEnd(‘=’);
    //     encoded += d % 2 > 0 ? “=” : “==”;
    // }
    // byte[] bytes = Convert.FromBase64String(encoded);
    // var decoded = Encoding.UTF8.GetString(bytes);
    // Abilitare questo nuovo metodo solo se tutti i ms vengono allineati con questo nuovo metodo
    // static newDecodeUrlWithUtfSupport(value: string): string {
    //     // Verifico se viene passato il vecchio formato dell'url
    //     const base64decode = window.atob(value);
    //     if (base64decode.startsWith('{"')) {
    //       // Fromato vecchio, vecchia decodifica
    //       return window.atob(decodeURIComponent(value));
    //     } else {
    //       // Formato nuovo, nuova decodifica
    //       return decodeURIComponent(base64decode)
    //     }
    // }

    // Abilitare questo nuovo metodo solo se tutti i ms vengono allineati con questo nuovo metodo
    // static newEncodeUrlWithUtfSupport(value: string): string {
    //     value = window.btoa(encodeURIComponent(value));
    //     // Sembra che gli ultimi "==" o "=" caratteri generati dalla funzione window.btoa creino problemi con i query string
    //     // quindi li rimuovo, successivamente non sarà necessario riaggiungerli
    //     if (value.substr(value.length - 2) === '==') {
    //         value = value.substring(0, value.length - 2);
    //     } else if (value.substr(value.length - 1) === '=') {
    //         value = value.substring(0, value.length - 1);
    //     }
    //     return value;
    // }

    static decodeUrl(value: string): string {
        return window.atob(decodeURIComponent(value));
    }

    static encodeUrl(value: string): string {

        value = window.btoa(value);
        // Sembra che gli ultimi "==" o "=" caratteri generati dalla funzione window.btoa creino problemi con i query string
        // quindi li rimuovo, successivamente non sarà necessario riaggiungerli
        if (value.substr(value.length - 2) === '==') {
            value = value.substring(0, value.length - 2);
        } else if (value.substr(value.length - 1) === '=') {
            value = value.substring(0, value.length - 1);
        }
        return encodeURIComponent(value);
    }

    static encodeObject(obj: any) {
        const plainObj = classToPlain(obj, { strategy: 'excludeAll' });
        const jsonObj = JSON.stringify(plainObj);
        return RoutingService.encodeUrl(jsonObj);
    }

    static decodeObject<T>(data: string, classType: ClassConstructor<T>): T {
        const jsonObject = RoutingService.decodeUrl(data);
        return plainToClass<T, Object>(
            classType, JSON.parse(jsonObject) as Object);
    }

    static getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    static getConfiguredUrl(route: ActivatedRouteSnapshot): string {
        return '/' + route.pathFromRoot
            .filter(v => v.routeConfig)
            .map(v => v.routeConfig!.path)
            .join('/');
    }

    navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(commands, extras);
    }

    /**
     * Il parametro jsonIdentity è necessario per impostare la rotta con l'identity encodata altrimenti se non viene passato
     * viene rimossa l'identiy se presente nella rotta
     */
    updateCurrentRoute(
        rootModelFullName: string = null,
        jsonIdentity: string = null,
        relativeUrl: string = null,
        queryString: string = null,
        internalRelativeUrl: string = null, // example url: /manage/receipt
    ) {

        if (internalRelativeUrl?.length > 0) {
            this.location.go(internalRelativeUrl);
            const params = new RouteChangeParams();
            params.updateOnlyUrl = true;
            params.rootModelFullName = rootModelFullName;
            params.internalFullUrl = internalRelativeUrl;
            this.routeChangeRequested.next(params);
            return;
        }

        // generare evento con aggiornamento solo dell'url

        // let openedWindow: Window;
        relativeUrl = relativeUrl ?? this.router.url;

        let encodedIdentityParam = null;

        if (jsonIdentity && jsonIdentity.length > 0) {
            encodedIdentityParam = RoutingService.encodeUrl(jsonIdentity);
        }

        const splittedUrl = relativeUrl.split('?');
        queryString = queryString ?? (splittedUrl[1] != null ? '?' + splittedUrl[1] : '')

        const splittedBaseUrl = splittedUrl[0].split('/');

        if (splittedBaseUrl.length === 4) { // ho una identity nell'url
            if (encodedIdentityParam) {

                splittedBaseUrl[splittedBaseUrl.length - 1] = encodedIdentityParam;
            } else {
                splittedBaseUrl.pop();
            }
        } else {
            if (encodedIdentityParam) {
                splittedBaseUrl.push(encodedIdentityParam);
            }
        }

        const baseUrl = splittedBaseUrl.join('/');
        this.location.go(baseUrl + queryString);

        const params = new RouteChangeParams();
        params.rootModelFullName = rootModelFullName;
        params.routeParam = encodedIdentityParam;
        params.queryParams = queryString;
        params.updateOnlyUrl = true;
        this.routeChangeRequested.next(params);
    }

    navigateInCrud(
        domainModelFullName: string,
        url: string,
        jsonIdentity = '',
        additionalQueryParams = new URLSearchParams(),
        addHistoryBackButton = false,
        blank = false
    ) {
        additionalQueryParams = additionalQueryParams ?? new URLSearchParams();

        url = EnvironmentHelper.getProxiedUrl(
            url, this.environmentConfiguration, this.unproxiedEnvironment
        );

        let encodedIdentityParam = null;

        if (jsonIdentity && jsonIdentity.length > 0) {
            encodedIdentityParam = RoutingService.encodeUrl(jsonIdentity);
        }

        additionalQueryParams.append('history-back', addHistoryBackButton ? 'true' : 'false');

        if (this._inIframe && blank == false) {
            additionalQueryParams.append('iframe', 'true');
        }

        if (addHistoryBackButton) {
            additionalQueryParams.append('history-back-url', encodeURIComponent(window.location.href));
        }

        if (!blank) {
            const params = new RouteChangeParams();
            params.rootModelFullName = domainModelFullName;
            params.routeParam = encodedIdentityParam;
            params.queryParams = '?' + additionalQueryParams.toString();
            params.updateOnlyUrl = true;
            this.routeChangeRequested.next(params);
        }

        this.redirectToUri(url + (encodedIdentityParam ? '/' + encodedIdentityParam : '') + '?' + additionalQueryParams.toString(), blank);
    }

    navigateInLongOp(
      domainModelFullName: string,
      url: string,
      jsonObject = '',
      additionalQueryParams = new URLSearchParams(),
      addHistoryBackButton = false,
      blank = false
  ) {
      additionalQueryParams = additionalQueryParams ?? new URLSearchParams();

      url = EnvironmentHelper.getProxiedUrl(
          url, this.environmentConfiguration, this.unproxiedEnvironment
      );

      let jsonObjectRouteParam = null;

      if (jsonObject && jsonObject.length > 0) {
          jsonObjectRouteParam = RoutingService.encodeUrl(jsonObject);
      }

      additionalQueryParams.append('history-back', addHistoryBackButton ? 'true' : 'false');

      if (this._inIframe && blank == false) {
          additionalQueryParams.append('iframe', 'true');
      }

      if (addHistoryBackButton) {
          additionalQueryParams.append('history-back-url', encodeURIComponent(window.location.href));
      }

      if (!blank) {
          const params = new RouteChangeParams();
          params.rootModelFullName = domainModelFullName;
          params.routeParam = jsonObjectRouteParam;
          params.queryParams = '?' + additionalQueryParams.toString();
          params.updateOnlyUrl = true;
          this.routeChangeRequested.next(params);
      }

      this.redirectToUri(url + (jsonObjectRouteParam ? '/' + jsonObjectRouteParam : '')  + '?' + additionalQueryParams.toString(), blank);
  }

  navigateInActivity(
      domainModelFullName: string,
      url: string,
      jsonObject = '',
      additionalQueryParams = new URLSearchParams(),
      addHistoryBackButton = false,
      blank = false
  ) {
      additionalQueryParams = additionalQueryParams ?? new URLSearchParams();

      url = EnvironmentHelper.getProxiedUrl(
          url, this.environmentConfiguration, this.unproxiedEnvironment
      );

      let jsonObjectRouteParam = null;

      if (jsonObject && jsonObject.length > 0) {
          jsonObjectRouteParam = RoutingService.encodeUrl(jsonObject);
      }

      additionalQueryParams.append('history-back', addHistoryBackButton ? 'true' : 'false');

      if (this._inIframe && blank == false) {
          additionalQueryParams.append('iframe', 'true');
      }

      if (addHistoryBackButton) {
          additionalQueryParams.append('history-back-url', encodeURIComponent(window.location.href));
      }

      if (!blank) {
          const params = new RouteChangeParams();
          params.rootModelFullName = domainModelFullName;
          params.routeParam = jsonObjectRouteParam;
          params.queryParams = '?' + additionalQueryParams.toString();
          params.updateOnlyUrl = true;
          this.routeChangeRequested.next(params);
      }

      this.redirectToUri(url + (jsonObjectRouteParam ? '/' + jsonObjectRouteParam : '')  + '?' + additionalQueryParams.toString(), blank);
  }

  redirectToLogin(
    customRedirectUrl: string = null,
    clearAuthData: boolean = false,
    clearRedirectUrl: boolean = false,
  ) {

      // Se esiste già un redirect url utilizza quello altrimenti lo crea lui partendo dall'url corrente
      let newHref: string
      const redirectUrlExists = window.location.href.split('?redirect-url=');
      if (redirectUrlExists.length > 1 && customRedirectUrl == null && clearRedirectUrl == false) {
          newHref = `${RoutingService.getBaseUrlOfUrl(this.environmentConfiguration.authenticationAppUrl)}/${AuthService.LOGIN_ALIAS_PATH}?redirect-url=${redirectUrlExists[1]}`;
      } else if (clearRedirectUrl == false) {
          newHref = `${RoutingService.getBaseUrlOfUrl(this.environmentConfiguration.authenticationAppUrl)}/${AuthService.LOGIN_ALIAS_PATH}?redirect-url=${customRedirectUrl ?? encodeURIComponent(window.location.href)}`;
      } else {
        newHref = `${RoutingService.getBaseUrlOfUrl(this.environmentConfiguration.authenticationAppUrl)}/${AuthService.LOGIN_ALIAS_PATH}`;
      }

      if (clearAuthData) {
          newHref = newHref + `${newHref.indexOf('?')> -1 ? '&' : '?'}${AuthService.CLEAR_AUTH_QUERY_KEY}=true`;
      }

      if (this._inIframe) {
          const params = new RouteChangeParams();
          // Visto che sto passando la rotta di login chi lo riceve gestirà automaticamente tutti i parametri correnti
          params.fullUrl = `${this.environmentConfiguration.authenticationAppUrl}`;
          params.rootModelFullName = null;
          params.routeParam = null;
          params.queryParams = null;
          params.updateOnlyUrl = false;
          this.routeChangeRequested.next(params);
      } else {
          window.location.href = newHref;
      }
  }

  static getBaseUrlOfUrl(url: string): string {
        let splitted = url.split('://')
        if (splitted?.length > 1) {
            const protocol = splitted[0];
            let pathname = splitted[1];
            const splittedPath = splitted[1].split('/');
            if (splittedPath?.length > 1) {
                pathname = splittedPath[0];
            }
            url = protocol + '://' + pathname
        }
        return url;
    }

    redirectToChangePassword(blank = false) {
        const fullUrl = `${RoutingService.getBaseUrlOfUrl(this.environmentConfiguration.authenticationAppUrl)}/${AuthService.CHANGE_PASSWORD_ALIAS_PATH}?redirect-url=${encodeURIComponent(window.location.href)}`;
        if (this._inIframe) {
            const params = new RouteChangeParams();
            params.fullUrl = fullUrl;
            params.rootModelFullName = null;
            params.routeParam = null;
            params.queryParams = null;
            params.inBlank = blank;
            params.updateOnlyUrl = false;
            this.routeChangeRequested.next(params);
        } else {
            this.redirectToUri(fullUrl, blank);
        }
    }

    redirectToChooseTenant(customRedirectUrl: string = null) {
        if (this._inIframe) {
            const params = new RouteChangeParams();
            params.fullUrl = `${RoutingService.getBaseUrlOfUrl(this.environmentConfiguration.authenticationAppUrl)}/${AuthService.LOGIN_CHOOSE_TENANT_ALIAS_PATH}`;
            params.rootModelFullName = null;
            params.routeParam = null;
            params.queryParams = null;
            params.updateOnlyUrl = false;
            this.routeChangeRequested.next(params);
        } else {
            const newHref = `${RoutingService.getBaseUrlOfUrl(this.environmentConfiguration.authenticationAppUrl)}/${AuthService.LOGIN_CHOOSE_TENANT_ALIAS_PATH}?redirect-url=${customRedirectUrl ?? encodeURIComponent(window.location.href)}`;
            this.redirectToUri(newHref);
        }
    }

    /**
     * Replace // with / and \\ with \ in uri path
     * @param uri uri to clean
     * @returns
     */
    static cleanUri(uri: string): string {

        const baseUrl = this.getBaseUrlOfUrl(uri);
        const splittedUri = uri.split(baseUrl);
        if (splittedUri?.length == 2) {
            const params = splittedUri[1].split('?');
            let pathName = splittedUri[1];
            let otherUrlParts = '';
            if (params?.length > 1) {
                pathName = params[0];
                params.shift();
                otherUrlParts = '?' + params.join('?');
            }
            // clean pathName
            pathName = pathName.split('//').join('/');

            uri = baseUrl + pathName + otherUrlParts;
        }
        return uri;
    }

    async redirectToUri(uri: string, blank = false) {

        uri = RoutingService.cleanUri(uri);

        if (this.environmentConfiguration.envType === 'DEV - PROXY') {
            const dividedUri = uri.split('.ntsinformatica.it/');

            // solo se sto andando su azurewebsites e il mio login base url corrisponde al mio host
            if (dividedUri.length > 1 &&
                dividedUri[0].indexOf('loginservice') > -1 &&
                this.environmentConfiguration.authenticationAppUrl.indexOf(this.environmentConfiguration.baseAppUrl) > -1
            ) {
                uri = this.environmentConfiguration.baseAppUrl + '/' + dividedUri[1];
            }
        }

        const concatenateOperator: string = uri.split('?').length > 1 ? '&' : '?';
        const refreshToken: string = await this.authService.getRefreshToken();
        const accessToken: string = await this.authService.getAccessToken();
        let newHref = `${uri}`;

        if (blank) {

            // new tab
            newHref += `${concatenateOperator}${AuthService.REFRESH_TOKEN_QUERY_KEY}=${refreshToken != null ? refreshToken : ''}&${AuthService.ACCESS_TOKEN_QUERY_KEY}=${accessToken}`;
            window.open(newHref, '_blank');
        } else if (newHref.indexOf(this.environmentConfiguration.baseAppUrl) > -1) {

            // routing
            this.router.navigateByUrl(newHref.substring(this.environmentConfiguration.baseAppUrl.length));
        } else {

            // redirect
            newHref += `${concatenateOperator}${AuthService.REFRESH_TOKEN_QUERY_KEY}=${refreshToken != null ? refreshToken : ''}&${AuthService.ACCESS_TOKEN_QUERY_KEY}=${accessToken}`;
            window.location.href = newHref;
        }
    }

    async redirectToEncodedUri(encodedUri: string, blank = false): Promise<void> {
        await this.redirectToUri(decodeURIComponent(encodedUri), blank);
    }

    async getDashboardUrl() {
        const dashBoardRootFullName = 'DashBoard.DashBoardObjects.Models.DashBoardLayout';
        const result = await PresentationCache.addIfNotExist(dashBoardRootFullName);
        if (!result) {
            throw new Error(`UIinfo error with ${dashBoardRootFullName}`);
        }
        return PresentationCache.get(dashBoardRootFullName);
    }

    async redirectToDashBoard(blank = false) {

        const url = await this.getDashboardUrl();

        if (this._inIframe) {
            const params = new RouteChangeParams();
            params.fullUrl = url;
            params.rootModelFullName = null;
            params.routeParam = null;
            params.queryParams = null;
            params.inBlank = blank;
            params.updateOnlyUrl = false;
            this.routeChangeRequested.next(params);
        } else {
            this.redirectToUri(url, blank);
        }
    }

    navigateInRelated(
        relatedDomainModelFullName: string,
        url: string,
        jsonIdentity: string,
        additionalQueryParams = new URLSearchParams(),
        isRoot: boolean,
        blank = false
    ) {
        additionalQueryParams = additionalQueryParams ?? new URLSearchParams();

        url = EnvironmentHelper.getProxiedUrl(
            url, this.environmentConfiguration, this.unproxiedEnvironment
        );

        let identityParam = null;

        if (jsonIdentity && jsonIdentity.length > 0) {
            identityParam = RoutingService.encodeUrl(jsonIdentity);
        }

        additionalQueryParams.append('related', isRoot ? 'false' : 'true');

        if (this._inIframe && blank == false) {
            additionalQueryParams.append('iframe', 'true');
        }

        if (!blank) {
            const params = new RouteChangeParams();
            params.rootModelFullName = relatedDomainModelFullName;
            params.routeParam = identityParam;
            params.queryParams = '?' + additionalQueryParams.toString();
            params.updateOnlyUrl = true;
            this.routeChangeRequested.next(params);
        }

        this.redirectToUri(url + '/' + identityParam + '?' + additionalQueryParams.toString(), blank);
    }
}
