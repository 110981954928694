import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, Renderer2, ElementRef, Inject } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { PresentationCache } from '../../../cache/presentation-cache';
import { UIStarter } from '../../../starter/ui-starter';
import { TenantProfile } from '../../../domain-models/license/tenant-profile';
import { TenantTypes } from '../../../domain-models/license/tenant-types';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { UserProfile } from '../../../domain-models/user/user-profile';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoutingService } from '../../../routing/routing.service';
import { LogService, OnlineService } from '@nts/std/utility';
import { BehaviorSubject } from 'rxjs';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { ToastMessage, ToastMessageType } from '../toast-message/toast-message';
import { skip } from 'rxjs/operators';
import { EnterpriseSelectorViewModel } from '../../../view-models/modal/enterprise-selector-modal-view-model';
import { ModalService } from '../../../view-models/modal/modal.service';
import { EnumItemInterface } from '../../../view-models/base-type/enum-property-view-model';
import { EnterpriseDataDto } from '../../../auth/dto/enterprise-data.dto';
import { EnterprisesListDto } from '../../../auth/dto/enterprise-list.dto';
import { EnterpriseInformationDto } from '../../../auth/dto/enterprise-information.dto';
import { FilledButtonComponent, FilledButtonType } from '../../shared/buttons/filled-button/filled-button.component';
import { Router } from '@angular/router';
import { AsyncPipe, DOCUMENT, Location, NgIf } from '@angular/common';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { OnDestroy } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { UserImageComponent } from '../../user-image/user-image.component';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { RibbonButtonComponent } from '../../shared/buttons/ribbon-button/ribbon-button.component';
import { BaseEnumTextBoxComponent } from '../../controls/core/base/base-enum-text-box/base-enum-text-box.component';
import { NTSTranslatePipe } from '../../pipe/nts-translation-pipe';
import { TextButtonComponent } from "../../shared/buttons/text-button/text-button.component";


@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatToolbarModule,
        MatIconModule,
        SvgIconComponent,
        UserImageComponent,
        MatMenuModule,
        SpinnerComponent,
        AsyncPipe,
        RibbonButtonComponent,
        BaseEnumTextBoxComponent,
        FilledButtonComponent,
        NTSTranslatePipe,
        TextButtonComponent
    ]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() hideToggle = false;
  @Output() toggleMenu = new EventEmitter<void>();
  @Output() onClickLogo = new EventEmitter<void>();
  @Output() reloadPage = new EventEmitter<void>();

  @ViewChild('tenantMenu') tenantMenu: MatMenu;
  @ViewChild('tenantMenuToggle') tenantMenuToggle: ElementRef;
  @ViewChild('tenantMenuTrigger') tenantMenuTrigger: MatMenuTrigger;

  @ViewChild('userMenu') userMenu: MatMenu;
  @ViewChild('userMenuToggle') userMenuToggle: ElementRef;
  @ViewChild('userMenuTrigger') userMenuTrigger: MatMenuTrigger;

  @ViewChild('enterpriseMenu') enterpriseMenu: MatMenu;
  @ViewChild('enterpriseMenuToggle') enterpriseMenuToggle: ElementRef;
  @ViewChild('enterpriseMenuTrigger') enterpriseMenuTrigger: MatMenuTrigger;

  filledButtonType = FilledButtonType;
  user: UserProfile;
  tenant: TenantProfile;
  url: string = '';
  baseUrl: string = '';
  tenantRefreshing = false;
  isOnline$: BehaviorSubject<boolean>;
  enterpriseList: EnterprisesListDto;
  enterpriseSelectorViewModel: EnterpriseSelectorViewModel;

  currentEnterprise: EnumItemInterface<number>;
  currentCompany: EnumItemInterface<number>;
  currentCompanyColor: string;
  isEnterpriseBarrierRequired: boolean = false;

  menuOutsideClickListener: () => void;

  constructor(
    public readonly env: EnvironmentConfiguration,
    private readonly authService: AuthService,
    private routingService: RoutingService,
    private router: Router,
    private onlineService: OnlineService,
    private toastMessage: ToastMessageService,
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
    private readonly location: Location,
    private renderer: Renderer2,
    @Inject(DOCUMENT) document: Document
  ) {

    this.isEnterpriseBarrierRequired =  env.isEnterpriseBarrierRequired;

    // nel caso in cui cambiano i dati dell'utente li aggiorno
    this.authService.onUserProfileUpdate.pipe(untilDestroyed(this)).subscribe(
      (user: UserProfile) => {
        this.user = user;
        this.cd.detectChanges();
      });

    // nel caso in cui cambiano i dati del tenant li aggiorno
    this.authService.onTenantProfileUpdate.pipe(untilDestroyed(this)).subscribe(
      (tenant: TenantProfile) => {
        this.tenant = tenant;
        this.cd.detectChanges();
      });

    this.routingService.handleIFrameFocusChanged().pipe(untilDestroyed(this)).subscribe(window => {
      this.closeDropdownMenu();
    });

    this.isOnline$ = onlineService.isOnline$;
  }

  ngOnDestroy(): void {
    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }

  ngAfterViewInit(): void {
    this.tenantMenu.overlayPanelClass = 'dropdown'
    this.userMenu.overlayPanelClass = 'dropdown'
    if (this.enterpriseMenu) {
      this.enterpriseMenu.overlayPanelClass = 'dropdown'
    }

  }

  startListener() {
    if (this.menuOutsideClickListener == null) {
      this.menuOutsideClickListener = this.renderer.listen('window', 'click',(e:Event)=>{
        if(
          (
            document.getElementById(this.userMenu?.panelId ?? '')?.contains(e.target as Node) != null ?
            !document.getElementById(this.userMenu.panelId).contains(e.target as Node) :
            true
          ) &&
          !this.userMenuToggle.nativeElement.contains(e.target) &&
          (
            document.getElementById(this.tenantMenu?.panelId ?? '')?.contains(e.target as Node) != null ?
            !document.getElementById(this.tenantMenu.panelId).contains(e.target as Node) :
            true
          ) &&
          !this.tenantMenuToggle.nativeElement.contains(e.target) &&
          (
            document.getElementById(this.enterpriseMenu?.panelId ?? '')?.contains(e.target as Node) != null ?
            !document.getElementById(this.enterpriseMenu.panelId).contains(e.target as Node) :
            true
          ) &&
          (
            this.enterpriseMenuToggle?.nativeElement?.contains(e?.target) != null ?
            !this.enterpriseMenuToggle.nativeElement.contains(e.target) :
            true
          )
        ){
          this.closeAll();
        }
      })
    }
  }

  onHiddenEnterpriseSelector() {
    this.updateEnterpriseData();
  }

  private async updateEnterpriseData() {

    const enterpriseData = await this.authService.getEnterpriseData(await this.authService.getTenantId());
    this.enterpriseList = await this.authService.getEnterpriseList();

    if (this.enterpriseList?.enterprises.length > 0 && enterpriseData != null && enterpriseData.enterpriseId > 0) {
      this.currentEnterprise = this.enterpriseList?.enterprises.map((e) => {
        const enterpriseEnum: EnumItemInterface<number> = {
          key: e.enterpriseId,
          description: e.name,
          isDefault: e.isDefault,
          descriptionExt: null
        }
        return enterpriseEnum;
      }).find((e:EnumItemInterface<number>) => e.key === enterpriseData.enterpriseId);
    }

    if (this.enterpriseList?.enterprises.length > 0 && enterpriseData != null && enterpriseData.companyId > 0 && this.currentEnterprise) {
      const found = this.enterpriseList?.enterprises.find((e: EnterpriseInformationDto) =>
        e.enterpriseId === this.currentEnterprise.key
      )
      if(found?.companies?.length > 0) {
        const foundCompany = found?.companies.find((c) => c.companyId === enterpriseData.companyId);
        if (foundCompany) {
          this.currentCompany = {
            key: foundCompany.companyId,
            description: foundCompany.name,
            isDefault: foundCompany.isDefault,
            descriptionExt: foundCompany.name
          }
          // this.currentCompanyColor = foundCompany.color?.length > 0 ? ColorUtility.shade(foundCompany.color, 0.7) : null
          this.currentCompanyColor = foundCompany.color?.length > 0 ? foundCompany.color : null
        }
      }
    }

    // Inizializzo il view model per il popup della selezione ditta azienda
    this.enterpriseSelectorViewModel = new EnterpriseSelectorViewModel();
    this.enterpriseSelectorViewModel.selectedEnterprise = this.currentEnterprise;
    this.enterpriseSelectorViewModel.selectedCompany = this.currentCompany;
    this.enterpriseSelectorViewModel.enterpriseListDto = this.enterpriseList;
    this.enterpriseSelectorViewModel.enterpriseList$.next(this.enterpriseList?.enterprises.map((e) => {
        const enterpriseEnum: EnumItemInterface<number> = {
          key: e.enterpriseId,
          description: e.name,
          isDefault: e.isDefault,
          descriptionExt: e.name
        }
        return enterpriseEnum;
    }));
    this.enterpriseSelectorViewModel.initialize();

    // if (this.enterpriseSelectorViewModel.selectedEnterprise && this.enterpriseSelectorViewModel.selectedCompany) {
    //   const found = this.enterpriseList?.enterprises.find((e) =>
    //     e.enterpriseId === this.enterpriseSelectorViewModel.selectedEnterprise.key
    //   )
    //   if (found?.companies?.length > 0) {
    //     this.enterpriseSelectorViewModel.filteredCompanyList = found.companies.map((c) => {
    //       const companyEnum: EnumItemInterface<number> = {
    //         key: c.companyId,
    //         description: c.name,
    //         isDefault: c.isDefault,
    //         descriptionExt: c.color
    //       }
    //       return companyEnum;
    //     })
    //   }
    // }
  }

  async changeEnterpriseAndCompany() {
    const dto = new EnterpriseDataDto();
    dto.enterpriseId = this.enterpriseSelectorViewModel?.selectedEnterprise?.key;
    dto.companyId = this.enterpriseSelectorViewModel?.selectedCompany?.key;
    if (dto.enterpriseId && dto.companyId) {
      if(this.routingService.inIframe) {
        await this.authService.setEnterpriseData(dto, await this.authService.getTenantId());

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(AuthService.ENTERPRISE_ID_QUERY_KEY, dto.enterpriseId.toString());
        searchParams.set(AuthService.COMPANY_ID_QUERY_KEY, dto.companyId.toString());

        // Ricarico la pagina con i query params aggiornati
        // window.location.search = searchParams.toString();
        this.location.go(window.location.pathname, searchParams.toString());

        this.reloadPage.next();
        this.closeAll();
      } else {
        // Se non sono in un iframe aggiungo/sostituisco nei query params correnti il companyId e enterpriseId scelti
        if ('URLSearchParams' in window) {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set(AuthService.ENTERPRISE_ID_QUERY_KEY, dto.enterpriseId.toString());
          searchParams.set(AuthService.COMPANY_ID_QUERY_KEY, dto.companyId.toString());

          // Ricarico la pagina con i query params aggiornati
          // window.location.search = searchParams.toString();
          this.location.go(window.location.pathname, searchParams.toString());
          this.reloadPage.next();
          this.closeAll();
        } else {
          LogService.warn('URLSearchParams non supportato! Impossibile cambiare azienda/ditta');
        }
      }
    } else {
      LogService.warn('Impossibile cambiare azienda, Dati azienda non validi.', dto)
      const toastMessage: ToastMessage = {
        title: MessageResourceManager.Current.getMessage('ChangeEnterpriseWithInvalidDataErrorTitle'),
        message: MessageResourceManager.Current.getMessage('ChangeEnterpriseWithInvalidDataErrorMessage'),
        type: ToastMessageType.warn
      }
      this.toastMessage.showToast(toastMessage);
    }
  }

  async ngOnInit(): Promise<void> {

    this.authService.onTenantProfileRefreshingInProgress.pipe(untilDestroyed(this)).subscribe((tenantRefreshing: boolean) => {
      this.tenantRefreshing = tenantRefreshing;
      this.cd.detectChanges();
    });

    if (this.isEnterpriseBarrierRequired) {
      await this.updateEnterpriseData();

      this.authService.onEnterpriseDataUpdate.pipe(untilDestroyed(this)).subscribe(async (enterpiseData: EnterpriseDataDto) => {
        await this.updateEnterpriseData();
        this.cd.detectChanges();
      })
    }

    // carico inizialmente il dato dallo storage
    this.user = await this.authService.getUserProfile();
    this.tenant = await this.authService.getTenantProfile();
    const profileDomainModelFullName = 'LoginService.UserObjects.Models.User';
    await PresentationCache.addIfNotExist(profileDomainModelFullName);

    // recupero l'url della login
    this.url = PresentationCache.get(profileDomainModelFullName)?.replace(new RegExp('user', 'g'), 'profile');

    // recupero il base url della login
    this.baseUrl = this.url?.split('/manage')[0];

    this.isOnline$.pipe(skip(1)).subscribe(() => {
      if (!this.onlineService.isOnline) {
        this.toastMessage.showToast({
          message: MessageResourceManager.Current.getMessage('GoToOfflineMessage'),
          title: MessageResourceManager.Current.getMessage('GoToOfflineTitle'),
          type: ToastMessageType.warn
        })
      } else {
        this.toastMessage.showToast({
          message: MessageResourceManager.Current.getMessage('std_ReturnToOnlineMessage'),
          title: MessageResourceManager.Current.getMessage('std_ReturnToOnlineTitle'),
          type: ToastMessageType.info
        })
      }
    })
    this.cd.detectChanges();
  }

  getTenantTypeDescription(tenantType: TenantTypes): string {
    return MessageResourceManager.Current.getMessage('std_TenantTypes_' + TenantTypes[tenantType]);
  }

  openProfile(): void {
    UIStarter.startOtherDomainClient(this.url, true);
  }

  changeTenant(): void {
    this.authService.changeTenant('', true);
  }

  onToggleMenu(): void {
    this.toggleMenu.emit();
  }

  logOut() {
    this.authService.logOut();
  }

  closeDropdownMenu() {
    if(this.tenantMenuTrigger){
       this.tenantMenuTrigger.closeMenu();
    }

    if(this.userMenuTrigger){
      this.userMenuTrigger.closeMenu();
    }

    if(this.isEnterpriseBarrierRequired && this.enterpriseMenuTrigger){
      this.enterpriseMenuTrigger.closeMenu();
    }
  }

  tenantMenuOpened() {
    this.userMenuTrigger.closeMenu();
    if(this.isEnterpriseBarrierRequired) {
      this.enterpriseMenuTrigger?.closeMenu()
    }
    this.startListener();
  }

  userMenuOpened() {
    this.tenantMenuTrigger.closeMenu();
    if(this.isEnterpriseBarrierRequired) {
      this.enterpriseMenuTrigger?.closeMenu()
    }
    this.startListener();
  }

  enterpriseMenuOpened() {
    this.userMenuTrigger.closeMenu();
    this.tenantMenuTrigger.closeMenu();
    this.startListener();
  }

  closeAll() {
    this.userMenuTrigger.closeMenu();
    this.tenantMenuTrigger.closeMenu();

    if(this.isEnterpriseBarrierRequired) {
      this.enterpriseMenuTrigger?.closeMenu();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }
  }
}

// https://stackoverflow.com/questions/51150422/how-to-detect-click-outside-of-an-element-in-angular
// https://medium.com/weekly-webtips/nice-angular-material-selection-dropdown-dc3147db07c6
// https://stackoverflow.com/questions/46967970/how-to-prevent-angular-material-mat-menu-from-closing
// https://betterprogramming.pub/best-way-to-overwrite-angular-materials-styles-e38dc8b84962
// https://gist.github.com/rveitch/84cea9650092119527bc
