import { BehaviorSubject, filter, skipUntil, switchMap, interval, firstValueFrom, map, merge, Observable, of, startWith, Subject, takeUntil, debounceTime, timeout } from "rxjs";
import { ZoomApiClient } from "../../../api-clients/zoom/zoom-api-client";
import { ToastMessageType } from "../../../components/layout/toast-message/toast-message";
import { ToastMessageService } from "../../../components/layout/toast-message/toast-message.service";
import { ZoomAdvancedOptions } from "../../../domain-models/find-options/zoom-advanced-options";
import { ServiceInfoIdentity } from "../../../domain-models/service-info/service-info.identity";
import { ZoomDataListDto } from "../../../domain-models/zoom/dto/zoom-data-list.dto";
import { ZoomDataDto } from "../../../domain-models/zoom/dto/zoom-data.dto";
import { ZoomIdentity } from "../../../domain-models/zoom/zoom.identity";
import { MessageCodes } from "../../../resources/message-codes";
import { MessageResourceManager } from "../../../resources/message-resource-manager";
import { BaseViewModel } from "../../base-view-model";
import { CommandFactory } from "../../commands/command-factory";
import { CommandTypes } from "../../commands/command-types";
import { UICommandSettingsManager } from "../../commands/ui-command-settings-manager";
import { UICommandInterface } from "../../commands/ui-command.interface";
import { ModalService } from "../../modal/modal.service";
import { ZoomArgs } from "../zoom-args";
import { AddZoomModalViewModel } from "./modals/add-zoom-modal-view-model";
import cloneDeep from 'lodash-es/cloneDeep';
import { EditZoomModalViewModel } from "./modals/edit-zoom-modal-view-model";
import { ZoomConfigurationDto } from "../../../domain-models/zoom/dto/zoom-configuration.dto";
import { ZoomParametersViewModel } from "../zoom-parameters-view-model";
import { ZoomResultsViewModel } from "../zoom-results-view-model";
import { FindValuesOptions } from "../../../domain-models";
import { OutputDataOrderDto } from "../../../domain-models/zoom/dto/output-data-order.dto";
import { ZoomColumnInfo } from '../../../view-models/zoom/zoom-column-info';
import { PinZoomToDashboardDto } from "../../../domain-models/zoom/dto/pin-zoom-to-dashboard.dto";
import { plainToClass } from "@nts/std/serialization";


export class ZoomListViewModel extends BaseViewModel {

    args: ZoomArgs
    addZoomCommand: UICommandInterface;
    starCommand: UICommandInterface;
    unstarCommand: UICommandInterface;
    editCommand: UICommandInterface;
    pinToDashboardCommand: UICommandInterface;
    storeCommand: UICommandInterface;
    zoomDataList: ZoomDataListDto;
    standardZoomData: ZoomDataDto;
    selectedZoomData: ZoomDataDto;
    selectedZoomDataChanged = new Subject<ZoomAdvancedOptions|void>();
    selectedZoomDataPropertyChanged = new Subject<void>();
    updateZoomAdvancedOptionsEmitted: Subject<ZoomAdvancedOptions> = new Subject();
    standardZoomAdvancedOptions: ZoomAdvancedOptions;
    isLoading = new BehaviorSubject<boolean>(false);
    zoomStateChanged = new BehaviorSubject<boolean>(false);
    allowEdit: boolean;

    constructor(
        private readonly apiClient: ZoomApiClient,
        private readonly modalService: ModalService,
        private readonly toastMessageService: ToastMessageService,
    ) {
        super();
    }

    getZoomDataListStorageKey() {
        return 'zoomDataList_' + this.args.requestedDomainModelMetadata.fullName;
    }

    getZoomOptionsListStorageKey() {
        return 'zoomOptionsList_' + this.args.requestedDomainModelMetadata.fullName;
    }

    getZoomResultSessionStorageKey() {
        return `${this.args.requestedDomainModelMetadata.fullName}_ZoomResult`;
    }

    getZoomConfigurationSessionStorageKey() {
        return `${this.args.requestedDomainModelMetadata.fullName}_ZoomConfiguration`;
    }

    private internalZoomParametersViewModel: ZoomParametersViewModel;
    private internalZoomResultsViewModel: ZoomResultsViewModel;

    setZoomParametersViewModel(zoomParametersViewModel: ZoomParametersViewModel) {
        this.internalZoomParametersViewModel = zoomParametersViewModel;
    }

    setZoomResultViewModel(zoomResultsViewModel: ZoomResultsViewModel) {
        this.internalZoomResultsViewModel = zoomResultsViewModel;
    }

    async init(args: ZoomArgs) {

        this.args = args;
        this.standardZoomAdvancedOptions = cloneDeep(this.args.zoomOptions);

        const standardZoomIdentity = new ZoomIdentity();
        standardZoomIdentity.zoomId = 0;

        this.standardZoomData = new ZoomDataDto(
            standardZoomIdentity,
            'Standard',
            false
        )

        const manager = new UICommandSettingsManager();
        this.addZoomCommand = manager.setUICommand(CommandTypes.CreateItem,
            CommandFactory.createUICommand((x) => this.addZoom(), () => of(true)));

        this.starCommand = manager.setUICommand(CommandTypes.StarItem,
            CommandFactory.createUICommand(
                (x) => this.starItem(),
                () => this.canStarItem(),
                undefined,
                () => this.canShowStarItem(),
            )
        );

        this.unstarCommand = manager.setUICommand(CommandTypes.UnstarItem,
            CommandFactory.createUICommand(
                (x) => this.unstarItem(),
                () => this.canUnstarItem(),
                undefined,
                () => this.canShowUnstarItem()
            )
        );

        this.storeCommand = manager.setUICommand(CommandTypes.Store,
            CommandFactory.createUICommand(
                (x) => this.storeItem(),
                () => this.canStoreItem(),
                undefined,
                () => this.canShowStoreItem()
            )
        );

        this.pinToDashboardCommand = manager.setUICommand(CommandTypes.PinToDashboard,
            CommandFactory.createUICommand(
                (x) => this.pinItemToDashboard(),
                () => this.canPinToDashboardItem(),
                undefined,
                () => of(true)
            )
        );

        this.editCommand = manager.setUICommand(CommandTypes.EditItem,
            CommandFactory.createUICommand(
                (x) => this.editItem(),
                () => this.canEditItem(),
                undefined,
                () => this.canShowEditItem(),
            )
        );

        const identity = new ServiceInfoIdentity();
        identity.fullName = args.requestedDomainModelMetadata.fullName;

        const zoomListResponse = await firstValueFrom(this.apiClient.getZoomDataListAsync(identity))
        if (zoomListResponse.operationSuccedeed) {
            this.zoomDataList = zoomListResponse.result;
            this.zoomDataList.zooms = [this.standardZoomData, ...this.zoomDataList.zooms]
        } else {
            this.zoomDataList = new ZoomDataListDto();
            this.zoomDataList.zooms = [this.standardZoomData]
        }
        // Check preferred
        this.selectedZoomData = this.standardZoomData;
        this.selectedZoomDataChanged.next();

        this.selectedZoomDataChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe(async (customOptions?: ZoomAdvancedOptions) => {
            this.isLoading.next(true);
            let options = await this.getZoomAdvancedOptionsFromZoomData(this.selectedZoomData)
            if(customOptions){
                options = customOptions;
            }
            this.allowEdit = options.allowEdit;
            this.updateZoomAdvancedOptionsEmitted.next(options);
            this.zoomStateChanged.next(false);
        })

        // Check zoom in session storage
        if ( (this.zoomDataList != undefined && this.zoomDataList != null) && this.zoomDataList.zooms.length > 1) {
            this.loadZoomDataFromLocalStorage()
        }

        this.initChangeSubscriber();
    }

    loadZoomDataFromLocalStorage(){
        const zoomConfigurationPlain = window.sessionStorage.getItem(this.getZoomConfigurationSessionStorageKey());
        if (zoomConfigurationPlain){
            const zoomConfiguration = plainToClass(ZoomConfigurationDto, JSON.parse(zoomConfigurationPlain), { strategy: 'excludeAll' });
            const zoomInList = this.zoomDataList.zooms.find((z) => z.zoomIdentity.zoomId == zoomConfiguration.zoomIdentity.zoomId)
            if (zoomInList){
                const options = new ZoomAdvancedOptions();
                options.filters = zoomConfiguration.findValuesOptions.filters;
                options.orderByPropertyNames = zoomConfiguration.findValuesOptions.orderByPropertyNames;
                options.outputProperties = zoomConfiguration.findValuesOptions.outputProperties;
                options.outputDataOrderList = zoomConfiguration.outputDataOrderList;
                options.allowEdit = zoomConfiguration.allowEdit

                this.selectedZoomData = zoomInList;
                this.selectedZoomDataChanged.next(options);
            }
        } else{
            const preferredZoomData = this.zoomDataList.zooms.find((z) => z.isPreferred)
            if (preferredZoomData) {
                this.selectedZoomData = preferredZoomData;
                this.selectedZoomDataChanged.next();
            }
        }
    }

    initChangeSubscriber() {
        /*
        delay 2 secondi, la creazione delle colonne della result non è instantanea
        quindi scatterebbe la zoomStateChanged, impostiamo un delay
        l'utente in 2 secondi non ha il tempo di aprire i result e modificare una colonna
        */
        this.isLoading.pipe(switchMap((isLoading) => merge(
            this.internalZoomResultsViewModel.resultsChanged.pipe(skipUntil(interval(2000))),
            this.internalZoomParametersViewModel.parametersChanged
        ).pipe(filter(() => !isLoading)))).subscribe(() => {
            this.zoomStateChanged.next(true);
        });
    }

    canStarItem(): Observable<boolean> {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged
        ).pipe(startWith(null), map(() =>
            this.selectedZoomData?.zoomIdentity?.zoomId != 0 && this.selectedZoomData?.isPreferred === false
        ))
    }

    canStoreItem() {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged,
            this.zoomStateChanged
        ).pipe(startWith(null), map(() =>
            this.selectedZoomData?.zoomIdentity?.zoomId != 0 && this.zoomStateChanged.value && this.allowEdit
        ))
    }

    canPinToDashboardItem() {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged,
            this.zoomStateChanged
        ).pipe(startWith(null), map(() =>
            this.selectedZoomData?.zoomIdentity?.zoomId != 0
        ))
    }

    canShowStoreItem() {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged
        ).pipe(startWith(null), map(() =>
            true
        ))
    }

    canEditItem() {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged,
            this.zoomStateChanged
        ).pipe(startWith(null), map(() =>
            this.selectedZoomData?.zoomIdentity?.zoomId != 0 && this.allowEdit
        ))
    }

    canShowEditItem() {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged
        ).pipe(startWith(null), map(() =>
            true
        ))
    }

    canUnstarItem(): Observable<boolean> {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged
        ).pipe(startWith(null), map(() =>
            this.selectedZoomData?.zoomIdentity?.zoomId != 0 && this.selectedZoomData?.isPreferred === true
        ))
    }

    canShowStarItem(): Observable<boolean> {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged
        ).pipe(startWith(null), map(() =>
            this.selectedZoomData?.isPreferred === false
        ))
    }

    canShowUnstarItem(): Observable<boolean> {
        return merge(
            this.selectedZoomDataChanged,
            this.selectedZoomDataPropertyChanged
        ).pipe(startWith(null), map(() =>
            this.selectedZoomData?.zoomIdentity?.zoomId != 0 && this.selectedZoomData?.isPreferred === true
        ))
    }

    async starItem() {

        this.isLoading.next(true);

        const response = await firstValueFrom(this.apiClient.setZoomPreferredAsync(this.selectedZoomData.zoomIdentity));
        this.toastMessageService.showToastsFromResponse(response);

        this.zoomDataList.zooms.forEach((z) => z.isPreferred = false);
        this.selectedZoomData.isPreferred = true;

        // Force Refresh of groups
        this.zoomDataList.zooms = [...this.zoomDataList.zooms];

        this.isLoading.next(false);

        this.selectedZoomDataPropertyChanged.next();
    }

    async unstarItem() {

        this.isLoading.next(true);

        const response = await firstValueFrom(this.apiClient.removeZoomPreferredAsync(this.selectedZoomData.zoomIdentity));
        this.toastMessageService.showToastsFromResponse(response);

        this.selectedZoomData.isPreferred = false;

        // Force Refresh of groups
        this.zoomDataList.zooms = [...this.zoomDataList.zooms];

        this.isLoading.next(false);

        this.selectedZoomDataPropertyChanged.next();
    }

    async currentZoomConfiguration(): Promise<ZoomConfigurationDto> {
        const zoomConfiguration = new ZoomConfigurationDto();
        zoomConfiguration.title = this.selectedZoomData.title;
        zoomConfiguration.zoomIdentity = this.selectedZoomData.zoomIdentity;
        zoomConfiguration.serviceInfoIdentity = new ServiceInfoIdentity();
        zoomConfiguration.serviceInfoIdentity.fullName = this.args.requestedDomainModelMetadata.fullName;
        zoomConfiguration.findValuesOptions = this.getSortedOptionsFromResult();
        zoomConfiguration.outputDataOrderList = await this.getOutputDataOrderList(zoomConfiguration.findValuesOptions);
        return zoomConfiguration;
    }

    async storeItem() {

        this.isLoading.next(true);

        const zoomConfiguration = await this.currentZoomConfiguration();
        const response = await firstValueFrom(this.apiClient.setZoomDataAsync(zoomConfiguration));
        this.toastMessageService.showToastsFromResponse(response);

        this.toastMessageService.showToast({
            title: MessageResourceManager.Current.getMessage(MessageCodes.Information),
            message: MessageResourceManager.Current.getMessage('ZoomUpdatedMessage'),
            type: ToastMessageType.info
        })

        this.isLoading.next(false);

        this.zoomStateChanged.next(false);

        this.selectedZoomDataPropertyChanged.next();
    }

    getSortedOptionsFromResult(): ZoomAdvancedOptions {
        const options = this.internalZoomParametersViewModel.getCurrentOptions();
        let optionList: ZoomColumnInfo[] = [];
        options.outputProperties.forEach(option => {

            const found = this.internalZoomResultsViewModel.columns.find((col) => {
                return col.propertyName == option;
            });
            if (found) {
                optionList.push(found)
            }
        });
        optionList.sort((a, b) => a.orderIndex - b.orderIndex);

        options.outputProperties.sort((a, b) => {
            return optionList.findIndex((ol) => ol.propertyName == a) - optionList.findIndex((ol) => ol.propertyName == b);
        });
        return options
    }

    async pinItemToDashboard() {

        this.isLoading.next(true);

        const pinZoomToDashboardDto = new PinZoomToDashboardDto();
        pinZoomToDashboardDto.zoomIdentity = this.selectedZoomData.zoomIdentity;
        const response = await firstValueFrom(this.apiClient.pinZoomToDashboardAsync(pinZoomToDashboardDto));
        this.toastMessageService.showToastsFromResponse(response);
        this.isLoading.next(false);
    }

    async editItem() {

        const editZoomModalViewModel = new EditZoomModalViewModel(
            this.selectedZoomData,
            this.modalService,
            this.toastMessageService,
            this.apiClient,
        );

        const response = await this.modalService.showCustomModalWithResultAsync<ZoomDataDto>(
            editZoomModalViewModel,
            false,
            false,
            false,
            {
                disableClose: true,
                panelClass: ['edit-zoom-modal']
            }
        );

        if (response.result) {

            const foundIndex = this.zoomDataList.zooms.findIndex(z => z.zoomIdentity.zoomId === response.result.zoomIdentity.zoomId);
            if (foundIndex > -1) {
                this.zoomDataList.zooms[foundIndex] = response.result;
                this.selectedZoomData = this.zoomDataList.zooms[foundIndex];
                const isPreferred = response.result.isPreferred;
                if (isPreferred) {
                    this.zoomDataList.zooms.forEach((z) => z.isPreferred = false);
                }
                this.selectedZoomData.isPreferred = isPreferred;
            }

            this.zoomDataList.zooms = [...this.zoomDataList.zooms];

            this.selectedZoomDataPropertyChanged.next();
        }
    }

    async getOutputDataOrderList(findValuesOptions: FindValuesOptions): Promise<OutputDataOrderDto[]> {
        const outputList: OutputDataOrderDto[] = [];
        const sortedProperties = await this.internalZoomResultsViewModel.getZoomSortedPropertiesForOutput(findValuesOptions);
        for (const [index, item] of sortedProperties.entries()) {
            const output = new OutputDataOrderDto();
            output.caption = item.displayName;
            output.isVisible = item.isVisible ?? true;
            output.propertyName = item.propertyPath;
            output.position = index;
            outputList.push(output);
        }
        return outputList;
    }

    getPositionFromResult(output: OutputDataOrderDto): number {
        const found = this.internalZoomResultsViewModel.columns.find((x) => x.propertyName == output.propertyName)
        if (found) {
            return found.orderIndex;
        }
        return 0;
    }

    async addZoom() {
        const addZoomModalViewModel = new AddZoomModalViewModel(
            this.modalService,
            this.toastMessageService,
            this.apiClient,
        );

        const zoomConfiguration: ZoomConfigurationDto = new ZoomConfigurationDto();
        zoomConfiguration.serviceInfoIdentity = new ServiceInfoIdentity();
        zoomConfiguration.serviceInfoIdentity.fullName = this.args.requestedDomainModelMetadata.fullName;
        zoomConfiguration.findValuesOptions = this.getSortedOptionsFromResult();
        zoomConfiguration.outputDataOrderList = await this.getOutputDataOrderList(zoomConfiguration.findValuesOptions);

        addZoomModalViewModel.zoomConfiguration = zoomConfiguration;

        const response = await this.modalService.showCustomModalWithResultAsync<ZoomConfigurationDto>(
            addZoomModalViewModel,
            false,
            false,
            false,
            {
                disableClose: true,
                panelClass: ['add-zoom-modal']
            }
        );

        if (response.result != null) {

            this.zoomDataList.zooms = [...this.zoomDataList.zooms, new ZoomDataDto(
                response.result.zoomIdentity,
                response.result.title,
                false
            )];

            this.selectedZoomData = this.zoomDataList.zooms[this.zoomDataList.zooms.length - 1];
            this.zoomStateChanged.next(false);
            this.selectedZoomDataChanged.next();
        }
    }

    async getZoomAdvancedOptionsFromZoomData(zoomData: ZoomDataDto = undefined): Promise<ZoomAdvancedOptions> {
        zoomData = zoomData ?? this.selectedZoomData;

        let options: ZoomAdvancedOptions;

        if (zoomData?.zoomIdentity?.zoomId === this.standardZoomData.zoomIdentity.zoomId) {
            options = this.standardZoomAdvancedOptions;
        } else {
            const response = await firstValueFrom(this.apiClient.getZoomConfiguration(zoomData.zoomIdentity));
            this.toastMessageService.showToastsFromResponse(response);
            if (response.operationSuccedeed) {
                options = new ZoomAdvancedOptions();
                options.filters = response.result.findValuesOptions.filters;
                options.orderByPropertyNames = response.result.findValuesOptions.orderByPropertyNames;
                options.outputProperties = response.result.findValuesOptions.outputProperties;
                options.outputDataOrderList = response.result.outputDataOrderList;
                options.allowEdit = response.result.allowEdit
            }
        }

        return options;
    }
}
