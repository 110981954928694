import extend from 'lodash-es/extend';
import { forkJoin, from, identity, Observable, of } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageResourceManager } from './message-resource-manager';
import { LocalstorageHelper, OnlineService, SerializabledDataType, TIMEOUT_CACHE } from '@nts/std/utility';

export enum TranslationLoaderFileType {
  StdDescriptions = 'stdDescriptions',
  StdMessages = 'stdMessages',
  StdCustomResource = 'stdCustomResource',
  Descriptions = 'descriptions',
  Messages = 'messages',
  CustomResource = 'customResource'
}

export const TRANSLATION_LOADER_STORAGE_KEY = 'translations';
export const BASE_PATH = 'assets/i18n/';
export const BASE_FWK_PATH = `${BASE_PATH}std/`;

export const DESCRIPTIONS_FILE = '/descriptions.json';
export const MESSAGES_FILE = '/messages.json';
export const CUSTOM_RESOURCE_FILE = '/custom-resource.json';


@Injectable({
  providedIn: 'root',
})
export class TranslationLoader {

  constructor(
    private http: HttpClient,
    private onlineService: OnlineService
  ) { }

  static getTranslationAssetKey(translationLoaderFileType: TranslationLoaderFileType, lang: string) {
    return `${translationLoaderFileType}_${lang}`
  }

  getTranslation(lang: string, fallbackLang: string = 'it'): Observable<any> {

    // per il momento considero solo due lettere per le lingue
    lang = lang.substring(0, 2).toLowerCase();
    fallbackLang = fallbackLang.substring(0, 2).toLowerCase();

    return from(LocalstorageHelper.getStorageItem(`${TRANSLATION_LOADER_STORAGE_KEY}_${lang}_${fallbackLang}`, undefined, false, false, false)).pipe(
      switchMap((storedTranslations) => {

        let obs: Observable<SerializabledDataType> = null;

          if(this.onlineService.isOnline === false) {
            if (storedTranslations) {
              // Caso offline e cache presente, ritorna subito l'oggetto storedJsonConfiguration
              obs = of(storedTranslations)
            } else {
              // Caso offline e cache non presente, ritorna oggetto vuoto
              obs = of({})
            }
          } else {

            let allAssets = {

              // Vengono generate da NTS Framework
              [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdDescriptions, fallbackLang)]: this.http.get<{[key:string]: string}>(BASE_FWK_PATH + fallbackLang + DESCRIPTIONS_FILE).pipe(
                  catchError(e => {
                      // LogService.warn('missing std descriptions translations!', e);
                      return of({});
                  })
              ),
              // Vengono generate da NTS Framework
              [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdMessages, fallbackLang)]: this.http.get<{[key:string]: string}>(BASE_FWK_PATH + fallbackLang + MESSAGES_FILE).pipe(
                  catchError(e => {
                      // LogService.warn('missing std messages translations!', e);
                      return of({});
                  })
              ),
              // Le custom resource vengono prese direttamente dalla libreria projects std e non auto generate
              [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdCustomResource, fallbackLang)]: this.http.get<{[key:string]: string}>(BASE_FWK_PATH + fallbackLang + CUSTOM_RESOURCE_FILE).pipe(
                  catchError(e => {
                      // LogService.warn('missing std custom translations!', e);
                      return of({});
                  })
              ),
              [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Descriptions, fallbackLang)]: this.http.get<{[key:string]: string}>(BASE_PATH + fallbackLang + DESCRIPTIONS_FILE).pipe(
                  catchError(e => {
                      // LogService.warn('missing app descriptions translations!', e);
                      return of({});
                  })
              ),
              [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Messages, fallbackLang)]: this.http.get<{[key:string]: string}>(BASE_PATH + fallbackLang + MESSAGES_FILE).pipe(
                  catchError(e => {
                      // LogService.warn('missing app messages translations!', e);
                      return of({});
                  })
              ),
              [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.CustomResource, fallbackLang)]: this.http.get<{[key:string]: string}>(BASE_PATH + fallbackLang + CUSTOM_RESOURCE_FILE).pipe(
                  catchError(e => {
                      // LogService.warn('missing app custom translations!', e);
                      return of({});
                  })
              )
            }

            if (fallbackLang != lang) {
              allAssets = {
                ...allAssets,

                // Vengono generate da NTS Framework
                [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdDescriptions, lang)]: this.http.get<{[key:string]: string}>(BASE_FWK_PATH + lang + DESCRIPTIONS_FILE).pipe(
                  catchError(e => {
                      // LogService.warn('missing std descriptions translations!', e);
                      return of({});
                  })
                ),
                // Vengono generate da NTS Framework
                [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdMessages, lang)]: this.http.get<{[key:string]: string}>(BASE_FWK_PATH + lang + MESSAGES_FILE).pipe(
                    catchError(e => {
                        // LogService.warn('missing std messages translations!', e);
                        return of({});
                    })
                ),
                // Le custom resource vengono prese direttamente dalla libreria projects std e non auto generate
                [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdCustomResource, lang)]: this.http.get<{[key:string]: string}>(BASE_FWK_PATH + lang + CUSTOM_RESOURCE_FILE).pipe(
                    catchError(e => {
                        // LogService.warn('missing std custom translations!', e);
                        return of({});
                    })
                ),
                [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Descriptions, lang)]: this.http.get<{[key:string]: string}>(BASE_PATH + lang + DESCRIPTIONS_FILE).pipe(
                    catchError(e => {
                        // LogService.warn('missing app descriptions translations!', e);
                        return of({});
                    })
                ),
                [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Messages, lang)]: this.http.get<{[key:string]: string}>(BASE_PATH + lang + MESSAGES_FILE).pipe(
                    catchError(e => {
                        // LogService.warn('missing app messages translations!', e);
                        return of({});
                    })
                ),
                [TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.CustomResource, lang)]: this.http.get<{[key:string]: string}>(BASE_PATH + lang + CUSTOM_RESOURCE_FILE).pipe(
                    catchError(e => {
                        // LogService.warn('missing app custom translations!', e);
                        return of({});
                    })
                )
              }
            }

            obs = forkJoin(allAssets).pipe(
              storedTranslations ?
                  // Caso online e cache presente, ritorna l'oggetto storedJsonConfiguration solot dopo un timeout di TIMEOUT_CACHE
                  timeout({
                      first: TIMEOUT_CACHE,
                      with: () => {
                          return of(storedTranslations);
                      }
                  }) :
                  // Caso online e cache non presente, ritorna identity
                  identity
            )
          }

          return obs.pipe(
              map(async (translations: {[key:string]: {[key:string]:string}}) => {
                  if (
                      translations && Object.keys(translations)?.length > 0
                  ) {
                      await LocalstorageHelper.setStorageItem(`${TRANSLATION_LOADER_STORAGE_KEY}_${lang}_${fallbackLang}`, translations, undefined, false, false);
                  }
                  TranslationLoader.mapTranslations(translations, lang, fallbackLang);
              })
          );
        })
      )
    }

  static mapTranslations(res: {[key:string]: {[key:string]:string}}, lang: string, fallbackLang: string = 'it') {

    // per il momento considero solo due lettere per le lingue
    lang = lang.substring(0, 2).toLowerCase();
    fallbackLang = fallbackLang.substring(0, 2).toLowerCase();

    const differentLanguage = lang != fallbackLang;

    let r: {
      [key: string]: string;
    } = {};

    if (differentLanguage) {
      r = extend(
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdDescriptions, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdDescriptions, lang)],

        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdMessages, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdMessages, lang)],

        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdCustomResource, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdCustomResource, lang)]
      );
    } else {
      r = extend(
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdDescriptions, lang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdMessages, lang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdCustomResource, lang)]
      );
    }

    Object.keys(r).forEach(k => {
      const nk = 'std_' + k;
      r[nk] = r[k];
      delete r[k];
    });

    if (differentLanguage) {
      return MessageResourceManager.Current.translations = extend(
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdDescriptions, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdDescriptions, lang)],

        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdMessages, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdMessages, lang)],

        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdCustomResource, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdCustomResource, lang)],

        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Descriptions, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Descriptions, lang)],

        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Messages, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Messages, lang)],

        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.CustomResource, fallbackLang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.CustomResource, lang)],
      );
    } else {
      return MessageResourceManager.Current.translations = extend(
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdDescriptions, lang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdMessages, lang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.StdCustomResource, lang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Descriptions, lang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.Messages, lang)],
        res[TranslationLoader.getTranslationAssetKey(TranslationLoaderFileType.CustomResource, lang)],
      );
    }
  }
}
