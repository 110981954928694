import { HttpClient } from '@angular/common/http';
import { NgZone, Injector, StaticProvider } from '@angular/core';
import { ProvidersForServiceAgent } from '../api-clients/providers-for-service-agent';
import { WebApiServiceAgent } from '../api-clients/web-api-service-agent';
import { UIFinderWebApiClient } from '../api-clients/ui-finder/ui-finder-web-api-client';
import { BaseUIStarter } from './base-ui-starter';
import { CRUDStarterAndReturnInterface } from './crud-starter-and-return.interface';
import { FindUIInfoResponse } from '../responses/find-ui-info-response';
import { FindUIInfoRequest } from '../requests/ui-finder-service-request';
import { ClientTypes } from '../domain-models/client-types';
import { FindUIInfo } from '../domain-models/ui-finder/find-ui-info';
import { IframeCRUDStarterAndReturnInterface } from './iframe-crud-starter-and-return.interface';
import { AuthService } from '../auth/auth.service';
import { CRUDStarterInterface } from './crud-starter.interface';
import { RoutingService } from '../routing/routing.service';
import { LogService, Message, OnlineService, TabSocket } from '@nts/std/utility';
import { classToPlain } from '@nts/std/serialization';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { LongOpStarterInterface } from './long-op-starter.interface';
import { ActivityStarterInterface } from './activity-starter.interface';
import { firstValueFrom } from 'rxjs';
import { ResponseCacheService } from '../responses/response-cache.service';

// Riferimenti per post Message
// https://github.com/apoterenko/ngx-post-message
export type IFrameGetter = (iframeBaseUrl: string) => Promise<any>;
export class UIStarter {
    static zone: NgZone;
    static httpClient: HttpClient;
    static environmentConfiguration: EnvironmentConfiguration;
    static authService: AuthService;
    static onlineService: OnlineService;
    static responseCacheService: ResponseCacheService;
    static routingService: RoutingService;
    static webApiServiceAgent: WebApiServiceAgent;

    static async findStartClient(
        domainModelFullName: string
    ): Promise<string> {

        let result: FindUIInfoResponse;

        try {
            const result = await UIStarter.findUIStartClient(domainModelFullName);
            return result.result.fullAddress.toLowerCase();
        } catch (error) {
            LogService.warn('Error in UIFinder->findStartClient', error, result);
            return '';
        }
    }

    private static async findUIStartClient(
        domainModelFullName: string
    ): Promise<FindUIInfoResponse> {

        const serviceAgentProviders: StaticProvider[] = ProvidersForServiceAgent.allStatic(
            this.httpClient,
            this.environmentConfiguration,
            this.authService,
            this.onlineService,
            this.responseCacheService,
            this.webApiServiceAgent
        );

        const injector: Injector = Injector.create({
            providers: serviceAgentProviders
        });

        const serviceAgent: WebApiServiceAgent  = injector.get<WebApiServiceAgent>(WebApiServiceAgent);

        const apiClient: UIFinderWebApiClient = new UIFinderWebApiClient(serviceAgent, this.onlineService);

        const request: FindUIInfoRequest = new FindUIInfoRequest();
        request.requestData = new FindUIInfo();
        request.requestData.clientType = ClientTypes.JS;
        request.requestData.objectFullName = domainModelFullName;

        // const response = new FindUIInfoResponse();
        // response.result = new UIInfo();
        // response.result.fullAddress = 'http://localhost:4200/manage/tenant';
        // return response;
        return await firstValueFrom(apiClient.findUIInfos(request));

    }

    // Utilizzata quando si lancia una form CRUD da F8
    static async startClientAndReturn(
        url: string,
        returnAction: (payload: Message<string>) => void, // per consentire alla form chiamante di recuperare i valori in caso di F8
        errorAction: (message: string) => void,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams,
    ): Promise<TabSocket> {

        return UIStarter.startCrudFromF8(url, returnAction, errorAction, jsonIdentity, additionalQueryParams);
    }

    // Utilizzata quando si lancia una form CRUD da F8
    static async startClientWithoutReturn(
        url: string,
        errorAction: (message: string) => void,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams,
    ): Promise<TabSocket> {

        return UIStarter.startCrudFromF8(url, null, errorAction, jsonIdentity, additionalQueryParams, false);
    }

    // Utilizzata per il lancio di una form CRUD da F8
    private static async startCrudFromF8(
        url: string,
        returnAction: (payload: Message<string>) => void, // per consentire alla form chiamante di recuperare i valori in caso di F8
        errorAction: (message: string) => void,
        jsonIdentity = null,
        additionalQueryParams = new URLSearchParams(),
        externalReturn = true
    ): Promise<TabSocket> {

        const uiStarter: CRUDStarterAndReturnInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterAndReturnInterface;

        // uiStarter.initBridge(UIStarter.zone);

        // TODO: Gestire meglio l'identity non valorizzata
        if (jsonIdentity === '{}' || jsonIdentity == null) {
            if (externalReturn) {
                return await uiStarter.showNewAndReturn(
                    url,
                    additionalQueryParams,
                    returnAction,
                    errorAction
                );
            } else {
                throw new Error('not implemented');
            }
        } else {
            if (externalReturn) {
                return await uiStarter.showReadAndReturn(
                    url,
                    jsonIdentity,
                    additionalQueryParams,
                    returnAction,
                    errorAction
                );
            } else {
                throw new Error('not implemented');
            }
        }
    }

    static updateCurrentRoute<TRouteParam extends any>(
        rootModelFullName?: string,
        routeParam?: TRouteParam,
        relativeUrl?: string,
        queryString?: string,
        internalRelativeUrl?: string
    ) {
        let jsonRouteParam: string = null
        if (routeParam) {
            const plainRouteParam: Record<string, any> = classToPlain(routeParam, { strategy: 'excludeAll' });
            jsonRouteParam = JSON.stringify(plainRouteParam);
        }
        const uiStarter: CRUDStarterInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterInterface;
        uiStarter.updateCurrentRoute(
            rootModelFullName,
            jsonRouteParam,
            relativeUrl,
            queryString,
            internalRelativeUrl
        );
    }

    static redirectToLogin(clearAuthData: boolean = false, clearRedirectUrl: boolean = false) {
        const uiStarter: CRUDStarterInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterInterface;
        uiStarter.redirectToLogin(clearAuthData, clearRedirectUrl);
    }

    static redirectToChooseTenant() {
        const uiStarter: CRUDStarterInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterInterface;
        uiStarter.redirectToChooseTenant();
    }

    static redirectToChangePassword(blank = false) {
        const uiStarter: CRUDStarterInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterInterface;
        uiStarter.redirectToChangePassword(blank);
    }

    static async redirectToDashboard(blank = false): Promise<void> {
        const uiStarter: CRUDStarterInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterInterface;
        return uiStarter.redirectToDashBoard(blank);
    }

    static async startIframeClientAndReturn(
        iframeBaseUrl: string,
        returnAction: (payload: Message<string>) => void, // per consentire alla form chiamante di recuperare i valori in caso di F8
        errorAction: (message: string) => void,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams,
        iframeGetter: IFrameGetter,
        externalReturn = true,
    ): Promise<TabSocket> {

        return UIStarter.startCrudFromF8InIframe(
            iframeBaseUrl,
            returnAction,
            errorAction,
            jsonIdentity,
            additionalQueryParams,
            iframeGetter,
            externalReturn,
        );
    }

    static startIframeClientAndWithoutReturn(
        iframeBaseUrl: string,
        errorAction: (message: string) => void,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams,
        iframeGetter: IFrameGetter
    ) {

        UIStarter.startCrudFromF8InIframe(
            iframeBaseUrl,
            null,
            errorAction,
            jsonIdentity,
            additionalQueryParams,
            iframeGetter,
            false
        );
    }

    // TODO sarebbe da trasformare in una funzione generica e non con nomenclatura specifica per crud
    private static async startCrudFromF8InIframe(
        iframeBaseUrl: string,
        returnAction: (payload: Message<string>) => void, // per consentire alla form chiamante di recuperare i valori in caso di F8
        errorAction: (message: string) => void,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams,
        iframeGetter: IFrameGetter,
        externalReturn = true
    ): Promise<TabSocket> {

        const uiStarter: IframeCRUDStarterAndReturnInterface = new BaseUIStarter(this.authService, this.routingService) as IframeCRUDStarterAndReturnInterface;

        if (jsonIdentity === '{}' || jsonIdentity == null) {
            return uiStarter.connectNewAndReturn(
                iframeBaseUrl,
                additionalQueryParams,
                returnAction,
                errorAction,
                iframeGetter,
                externalReturn
            );
        } else {
            return uiStarter.connectReadAndReturn(
                iframeBaseUrl,
                jsonIdentity,
                additionalQueryParams,
                returnAction,
                errorAction,
                iframeGetter,
                externalReturn
            );
        }
    }

    /**
     * @description Use this method only if you haven't access to RoutingService instance. It's better to use always RoutingService.navigateInRelated instead.
     * @todo We should move this method to a static method of RoutingService
     *
     * @param relatedDomainModelFullName
     * @param url
     * @param jsonIdentity
     * @param isRoot
     */
    static startRelatedClient(
        relatedDomainModelFullName: string,
        url: string,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams,
        isRoot: boolean,
        blank = false
    ) {

        const uiStarter: CRUDStarterInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterInterface;
        uiStarter.showReadRelated(
            relatedDomainModelFullName,
            url,
            jsonIdentity,
            additionalQueryParams,
            isRoot,
            blank
        );
    }

    static startCrudClient(
        domainModelFullName: string,
        url: string,
        jsonIdentity?: string,
        additionalQueryParams?: URLSearchParams,
        addHistoryBackButton = false,
        blank = false
    ) {
        const uiStarter: CRUDStarterInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterInterface;
        uiStarter.startCrud(
            domainModelFullName,
            url,
            jsonIdentity,
            additionalQueryParams,
            addHistoryBackButton,
            blank
        );
    }

    static startLongOpClient(
        domainModelFullName: string,
        url: string,
        jsonObject?: string,
        additionalQueryParams?: URLSearchParams,
        addHistoryBackButton = false,
        blank = false
    ) {
        const uiStarter: LongOpStarterInterface = new BaseUIStarter(this.authService, this.routingService) as LongOpStarterInterface;
        uiStarter.startLongOp(
            domainModelFullName,
            url,
            jsonObject,
            additionalQueryParams,
            addHistoryBackButton,
            blank
        );
    }

    static startActivityClient(
        domainModelFullName: string,
        url: string,
        jsonObject?: string,
        additionalQueryParams?: URLSearchParams,
        addHistoryBackButton = false,
        blank = false
    ) {
        const uiStarter: ActivityStarterInterface = new BaseUIStarter(this.authService, this.routingService) as ActivityStarterInterface;
        uiStarter.startActivity(
            domainModelFullName, 
            url, 
            jsonObject,
            additionalQueryParams,
            addHistoryBackButton, 
            blank
        );
    }

    /**
     * Avvia un client di un altro dominio nella stessa pagina passandogli in query string i tokens
     */
    static startOtherDomainClient(
        url: string, blank = false
    ) {
        const uiStarter: CRUDStarterInterface = new BaseUIStarter(this.authService, this.routingService) as CRUDStarterInterface;
        uiStarter.startOtherDomainWithTokens(url, blank);
    }
}
