import { CRUDStarterInterface } from './crud-starter.interface';
import { CRUDStarterAndReturnInterface } from './crud-starter-and-return.interface';
import { IframeCRUDStarterAndReturnInterface } from './iframe-crud-starter-and-return.interface';
import { AuthService } from '../auth/auth.service';
import { RoutingService } from '../routing/routing.service';
import { LongOpStarterInterface } from './long-op-starter.interface';
import { LocalstorageHelper, Message, TabSocket } from '@nts/std/utility';
import { UIStarter } from './ui-starter';
import { PostMessageBridgeInterface, MessageType, DEFAULT_BRIDGE_NAME } from '@nts/std/utility';
import { ActivityStarterInterface } from './activity-starter.interface';

export class BaseUIStarter implements ActivityStarterInterface, LongOpStarterInterface, CRUDStarterInterface, CRUDStarterAndReturnInterface, IframeCRUDStarterAndReturnInterface {

    protected _bridge: PostMessageBridgeInterface;

    constructor(
        private authService: AuthService,
        private routingService: RoutingService,
    ) { }

    updateCurrentRoute(
        rootModelFullName?: string,
        jsonIdentity?: string,
        relativeUrl?: string,
        queryString?: string,
        internalRelativeUrl?: string
    ) {
        this.routingService.updateCurrentRoute(
            rootModelFullName,
            jsonIdentity,
            relativeUrl,
            queryString,
            internalRelativeUrl
        );
    }

    async connectReadAndReturn(
        iframeBaseUrl: string,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams = new URLSearchParams(),
        returnAction: (payload: Message<string>) => void,
        errorAction: (identity: string) => void,
        iframeGetter: (iframeUrl: string) => Promise<any>,
        externalReturn = true
    ): Promise<TabSocket> {
        const identityParam: string = RoutingService.encodeUrl(jsonIdentity);

        additionalQueryParams = additionalQueryParams ?? new URLSearchParams();
        additionalQueryParams.append('iframe', 'true');

        if (externalReturn) {
            additionalQueryParams.append('external-return', 'true');
        }

        let fullUrlToOpen: string = iframeBaseUrl + '/' + identityParam + '?' + additionalQueryParams.toString();
        fullUrlToOpen = await this.authService.getUrlWithTokens(fullUrlToOpen);
        const iframeWindow: Window = await iframeGetter(fullUrlToOpen);

        // Gestisco il cross-site local storage
        LocalstorageHelper.startListener(iframeWindow);

        return this.socketListen(iframeWindow, returnAction, errorAction);
    }

    async showReadAndReturn(
        url: string,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams = new URLSearchParams(),
        returnAction: (payload: Message<string>) => void,
        errorAction: (message: string) => void): Promise<TabSocket> {
        let openedWindow: Window;

        const identityParam: string = RoutingService.encodeUrl(jsonIdentity);

        additionalQueryParams = additionalQueryParams ?? new URLSearchParams();
        additionalQueryParams.append('external-return', 'true');

        let fullUrlToOpen: string = url + '/' + identityParam + '?' + additionalQueryParams.toString();

        fullUrlToOpen = await this.authService.getUrlWithTokens(fullUrlToOpen);

        openedWindow = window.open(fullUrlToOpen);

        return this.socketListen(openedWindow, returnAction, errorAction);
    }

    // showReadAndReturn(url: string, jsonIdentity: string, returnAction: (identity: string) => void, errorAction: (message: string) => void) {
    //     let openedWindow: Window;

    //     const identityParam = RoutingService.encodeUrl(jsonIdentity);

    //     let fullUrlToOpen = url + '/' + identityParam + '?external-return=true';

    //     fullUrlToOpen = await this.authService.getUrlWithTokens(fullUrlToOpen);

    //     openedWindow = window.open(fullUrlToOpen);

    //     this.socketListen(openedWindow, returnAction, errorAction, 'external-return');
    // }

    async showNewAndReturn(
        url: string,
        additionalQueryParams: URLSearchParams = new URLSearchParams(),
        returnAction: (payload: Message<string>) => void,
        errorAction: (identity: string) => void
    ): Promise<TabSocket> {
        let openedWindow: Window;
        const concatenateOperator: string = url.split('?').length > 1 ? '&' : '?';

        additionalQueryParams = additionalQueryParams ?? new URLSearchParams();
        additionalQueryParams.append('external-return', 'true');

        let fullUrlToOpen: string = url + concatenateOperator + additionalQueryParams.toString();
        fullUrlToOpen = await this.authService.getUrlWithTokens(fullUrlToOpen);
        openedWindow = window.open(fullUrlToOpen);
        return this.socketListen(openedWindow, returnAction, errorAction);
    }
    // showNewAndReturn(url: string, returnAction: (identity: string) => void, errorAction: (identity: string) => void) {
    //     let openedWindow: Window;
    //     const concatenateOperator = url.split('?').length > 1 ? '&' : '?';
    //     let fullUrlToOpen = url + concatenateOperator + 'external-return=true';
    //     fullUrlToOpen = await this.authService.getUrlWithTokens(fullUrlToOpen);
    //     openedWindow = window.open(fullUrlToOpen);
    //     this.socketListen(openedWindow, returnAction, errorAction, 'external-return');
    // }

    async startOtherDomainWithTokens(
        url: string,
        blank: boolean
    ) {
        const fullUrlToOpen: string = await this.authService.getUrlWithTokens(url);
        window.open(fullUrlToOpen, blank ? '_blank' : '_self');
    }

    async connectNewAndReturn(
        iframeBaseUrl: string,
        additionalQueryParams: URLSearchParams = new URLSearchParams(),
        returnAction: (payload: Message<string>) => void,
        errorAction: (identity: string) => void,
        iframeGetter: (iframeUrl: string) => Promise<Window>,
        externalReturn = true
    ): Promise<TabSocket> {
        const concatenateOperator: string = iframeBaseUrl.split('?').length > 1 ? '&' : '?';

        additionalQueryParams = additionalQueryParams ?? new URLSearchParams();
        additionalQueryParams.append('iframe', 'true');

        if (externalReturn) {
            additionalQueryParams.append('external-return', 'true');
        }

        let fullUrlToOpen: string = iframeBaseUrl + concatenateOperator + additionalQueryParams.toString();
        fullUrlToOpen = await this.authService.getUrlWithTokens(fullUrlToOpen);
        const iframeWindow: Window = await iframeGetter(fullUrlToOpen);

        // Gestisco il cross-site local storage
        LocalstorageHelper.startListener(iframeWindow);

        return this.socketListen(iframeWindow, returnAction, errorAction);
    }

    redirectToLogin(clearAuthData: boolean = false, clearRedirectUrl: boolean = false): void {
        this.routingService.redirectToLogin(null, clearAuthData, clearRedirectUrl);
    }

    redirectToChooseTenant() {
        this.routingService.redirectToChooseTenant();
    }

    redirectToChangePassword(blank = false) {
        this.routingService.redirectToChangePassword(blank);
    }

    async redirectToDashBoard(blank = false): Promise<void> {
        this.routingService.redirectToDashBoard(blank);
    }

    showReadRelated(
        relatedDomainModelFullName: string,
        url: string,
        jsonIdentity: string,
        additionalQueryParams: URLSearchParams,
        isRoot = false,
        blank = false
    ): void {
        this.routingService.navigateInRelated(
            relatedDomainModelFullName,
            url,
            jsonIdentity,
            additionalQueryParams,
            isRoot,
            blank
        );
    }

    startCrud(
        relatedDomainModelFullName: string,
        url: string,
        jsonIdentity?: string,
        additionalQueryParams?: URLSearchParams,
        addHistoryBackButton?: boolean,
        blank = false
    ): void {
        this.routingService.navigateInCrud(
            relatedDomainModelFullName,
            url,
            jsonIdentity,
            additionalQueryParams,
            addHistoryBackButton,
            blank
        );
    }

    startLongOp(
        domainModelFullName: string,
        url: string,
        jsonObject?: string,
        additionalQueryParams?: URLSearchParams,
        addHistoryBackButton?: boolean,
        blank = false
    ): void {
        this.routingService.navigateInLongOp(
            domainModelFullName,
            url,
            jsonObject,
            additionalQueryParams,
            addHistoryBackButton,
            blank
        );
    }

    startActivity(
        domainModelFullName: string,
        url: string,
        jsonObject?: string,
        additionalQueryParams?: URLSearchParams,
        addHistoryBackButton?: boolean,
        blank = false
    ): void {
        this.routingService.navigateInActivity(
            domainModelFullName, 
            url, 
            jsonObject,
            additionalQueryParams,
            addHistoryBackButton,
            blank
        );
    }

    notifyClosed() {
        const currentWindow: Window = window;
        currentWindow.close();
    }

    private socketListen(
        openedWindow: Window,
        returnAction: (payload: Message<string>) => void,
        errorAction: (message: string) => void,
        bridgeName = null
    ): TabSocket {

        const socket: TabSocket = new TabSocket(UIStarter.zone, openedWindow, bridgeName ?? DEFAULT_BRIDGE_NAME);
        const closeSubscription = socket.onClose.subscribe(() => {
            if (returnAction) {
                returnAction(new Message('closed-connection', MessageType.ClosedConnection));
            }
            socket.destroy();
        });

        socket.listen<string>((message) => {

            // clearTimeout(timeout);
            // closeSubscription.unsubscribe();
            if (returnAction) {
                returnAction(message);
            }
            // socket.destroy();
        });
        return socket;
    }

    // private socketListen(openedWindow: Window, returnAction: (identity: string) => void,
    //     errorAction: (message: string) => void, socketName: string) {

    //     const socket = new TabSocket(openedWindow, socketName);
    //     const closeSubscription = socket.onClose.subscribe(() => {
    //         returnAction(undefined);
    //         socket.destroy();
    //     });
    //     socket.listen<string>((message) => {
    //         // clearTimeout(timeout);
    //         closeSubscription.unsubscribe();
    //         returnAction(message.data);
    //         socket.destroy();
    //     });
    //     // const timeout = setTimeout(() => {
    //     //     errorAction('si è verificato un errore durante l\'apertura');
    //     // }, 60000);


    //     // const clearEventHandler = () => {
    //     //     window.removeEventListener('message', loadEventHandler);
    //     // };
    //     // const timeout = setTimeout(() => {
    //     //     clearEventHandler();
    //     //     errorAction('si è verificato un errore durante l\'apertura');
    //     // }, 60000);
    //     // const loadEventHandler = (loadMessage: any) => {
    //     //     const socket = new TabSocket(openedWindow, socketName);
    //     //     socket.listen<string>((message) => {
    //     //         closeSubscription.unsubscribe();
    //     //         returnAction(message.data);
    //     //         socket.destroy();
    //     //     });
    //     //     const closeSubscription = socket.onClose.subscribe(() => {
    //     //         returnAction(undefined);
    //     //         socket.destroy();
    //     //     });
    //     //     clearTimeout(timeout);
    //     //     clearEventHandler();
    //     // };
    //     // window.addEventListener('message', loadEventHandler);
    // }
}
